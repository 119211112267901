

.container{
    position: relative;
    padding: 0;

    width: fit-content;
    margin: 20px auto;
}

.panel {
    position: absolute;
    margin: 10px;
    padding: 10px;
    background: #1ea7fd22; /* Paper color */
    border: 1px solid #1ea7fd; /* Optional for rounded corners */
    border-radius: 8px; /* Optional for rounded corners */
}

.loading {
    position: absolute;
    padding: 20px;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1ea7fd22; /* Paper color */
    border: 1px solid #1ea7fd; /* Optional for rounded corners */
    border-radius: 8px; /* Optional for rounded corners */
}


canvas {
    background: white; /* Paper color */
    border-radius: 8px; /* Optional for rounded corners */
    box-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 3px 6px rgba(0, 0, 0, 0.1),
            0px 5px 10px rgba(0, 0, 0, 0.1),
            0px 8px 15px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0;
}

.selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.selector1 label, .panel .X{
    font-weight: 700;
    color: #0761cc;
}
.selector2 label, .panel .O{
    font-weight: 700;
    color: #fa1818;
}

.selector3 {
    margin-top: 20px;
}
